<template>
  <b-container class="text-left">
    <h1 class="text-left">{{ $t('terms.terms') }}</h1>
    <h3>
      {{ $t('terms.price') }}
    </h3>
    <p>
      {{ $t('terms.priceText') }}
    </p>
    <p>
      {{ $t('terms.priceText2') }}
    </p>
    <h3>
      {{ $t('terms.warranty') }}
    </h3>
    <p>
      {{ $t('terms.warrantyText') }}
    </p>
    <h3>
      {{ $t('terms.withdrawal') }}
    </h3>
    <p>
      {{ $t('terms.withdrawalText') }}
    </p>
    <h3>
      {{ $t('terms.contactInfo') }}
    </h3>
    <p>
      {{ $t('terms.contactInfoText') }}
    </p>
  </b-container>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      text6: `
         You have to connect a USB storage device of suitable size (storage capacity) and read/write performance.
         Please make sure that the USB storage device is formatted with the methods integrated into the STB (if there are).
         In any case please check the detailed instructions of the STB for PVR usage.
        `
    }
  }
}
</script>

<style scoped>

.b1{
  background-color: #0000ff!important;
  border-color: #0000ff!important;
}

.b1:hover{
  background-color: #0000aa!important;
  border-color: #0000aa!important;
}

</style>
